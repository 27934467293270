import "./tease-row.scss";
import "./tease-row-2-up.scss";
import "./tease-row-3-up.scss";

import cn from "clsx";
import {
  Alignment,
  EditAttributes,
  TeaseStyle,
} from "design-system/types/types";
import { ArticleTeaseProps } from "design-system/components/primitives/article-tease/article-tease";
import {
  ComponentHeader,
  ComponentHeaderProps,
} from "design-system/components/primitives/component-header/component-header";
import { LazyTeaseRowTease, TeaseRowTease } from "./tease-row-tease";

export type TeaseRowWrap = "wrap" | "no-wrap";

export interface TeaseRowProps {
  align?: Alignment;
  className?: string;
  headerContent?: ComponentHeaderProps;
  teases?: Array<LazyTeaseRowTease>;
  teaseStyle?: TeaseStyle;
  showPhone?: boolean;
  showJobTitle?: boolean;
  teasesPerRow?: string;
  wrap?: TeaseRowWrap;
  imagesInset?: boolean;
  HeadingLevel?: "h2" | "h3" | "h4";
  HeadingLevelTease?: "h2" | "h3" | "h4" | "h5";
  editAttributes?: {
    teases?: EditAttributes;
  };
}

/**
 * ## See it in use on...
 * - The [article kitchen sink page](/story/example-pages-article-pages-kitchen-sink--story)
 *
 * - **`id: CB-020-000-06`**
 * - **`data-region: cb__tease-row__article`**
 */
export function TeaseRow({
  align = "wide",
  className,
  headerContent,
  teases,
  teaseStyle,
  showPhone,
  showJobTitle,
  teasesPerRow,
  wrap = "no-wrap",
  imagesInset,
  HeadingLevel = "h2",
  HeadingLevelTease = "h3",
  editAttributes,
}: TeaseRowProps) {
  if (!Array.isArray(teases)) teases = [];
  const rowUp = teasesPerRow || teases.length;

  const isMixedTeases =
    teases.some(
      (tease) => tease.props?.type === "Article" && tease.props.kaltura,
    ) &&
    teases.some(
      (tease) => tease.props?.type === "Article" && tease.props.image,
    );

  const wrapperClass = cn(
    `hbs-global-align-${align}`,
    "hbs-component--tease-row",
  );
  const teaseRowClass = cn(
    "hbs-tease-row",
    `hbs-tease-row--${rowUp}-up`,
    `hbs-tease-row--${wrap}`,
    isMixedTeases && "hbs-tease-row--mixed-tease",
    className,
  );

  let teaseType = teases[0]?.props?.type?.toLowerCase();

  // Show the Tease Row only if there's more than one tease for non-article/participant story teases
  if (
    teases.length < 2 &&
    teaseType !== "article" &&
    teaseType !== "participantstory" &&
    teaseType !== "person"
  ) {
    return null;
  }

  if (teaseType === "article") {
    const articleTease = teases[0]?.props as ArticleTeaseProps | undefined;
    // Small content tease variant allows for a single tease
    if (articleTease?.isLogo) {
      teaseType = "logo";
    } else if (articleTease?.kaltura) {
      teaseType = "kaltura";
    } else if (!articleTease?.byline) {
      teaseType = "content";
    }
  }

  const dataRegion = `cb__tease-row__${teaseType}`;
  const headingLevelTease = HeadingLevelTease
    ? HeadingLevelTease
    : headerContent
      ? "h3"
      : "h2";

  return (
    <div className={wrapperClass} data-region={dataRegion}>
      <section className="hbs-tease-row-container">
        {headerContent && (
          <ComponentHeader {...headerContent} HeadingLevel={HeadingLevel} />
        )}

        <ul {...editAttributes?.teases} className={teaseRowClass}>
          {teases.map((tease, i) => (
            <TeaseRowTease
              key={i}
              tease={tease}
              teaseStyle={teaseStyle}
              headingLevel={headingLevelTease}
              showPhone={showPhone}
              showJobTitle={showJobTitle}
              articleImageInset={imagesInset}
            />
          ))}
        </ul>
      </section>
    </div>
  );
}
